import Icon_HumanPassLogoWithText from "@/components/icons/Icon_HumanPassLogoWithText";
import JoinWaitlistButton from "./join-waitlist-button";

export default function Navbar({ showWaitinglistButton = true }) {
  return (
    <nav className="p-6">
      <div className="flex justify-start md:hidden">
        <a href="/" className="cursor-pointer w-[35%]">
          <Icon_HumanPassLogoWithText />
        </a>
      </div>

      <div className="container justify-between hidden mx-auto mt-5 md:flex">
        <a
          href="/"
          className="w-64 cursor-pointer md:flex md:items-center md:space-x-3"
        >
          <Icon_HumanPassLogoWithText />
        </a>

        {showWaitinglistButton ? (
          <div className="flex justify-center">
            <JoinWaitlistButton variant="small" />
          </div>
        ) : null}
      </div>
    </nav>
  );
}
